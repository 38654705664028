export const DATE_QUERY_FORMAT = 'yyyy-MM-DD';
export const IDLE_TIMEOUT = 600000; // in miliseconds

export const INVOICE_STATUSES = {
  DECLINED: 'declined',
  ACCEPTED: 'accepted',
  APPROVED: 'approved',
  DRAFT: 'draft',
  CONFIRMED: 'confirmed',
};
export const DEBIT_CREDIT = {
  DEBIT: 'debit',
  CREDIT: 'credit',
};
export const REGEX_CONSTANTS = {
  VAT: '^NL\\d{9}[A-Z]\\d{2}$',
  IBAN: '^NL\\d{2}[A-Z]{4}\\d{10}$',
  PHONE_NUMBER: '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$',
};
export const NAME: string = 'name';
export const LAW: string = 'law';
export const COLLECTION_NAMES = {
  CONTINUE_WITHOUT_HELP_OPTIONS: 'continue-without-help-options',
  STARTED_WITH_CRISIS_OPTIONS: 'started-with-crisis',
  TYPE_OF_HELP_OPTIONS: 'type-of-help',
};

export const REPLACEMENT_LINE_GROUP_ACTIONS = {
  CONFIRM: 'confirm',
  PROCESS: 'process',
  DELETE: 'delete',
  RETURN_TO_DRAFT: 'returnToDraft',
};
export const SELECT_ALL_KEY = 'billing.labels.SelectAll';

export const SORT = {
  DESCENDING: 'desc',
  ASCENDING: 'asc',
};

export const LOGBOOK_MONTH_LIMITS = {
  EMPTY_FULLNAME_FUNCTION_MAX_MONTH: 63,
  FILLED_FULLNAME_FUNCTION_MAX_MONTH: 186,
};

export const LOGBOOK_MONTH_AMOUNTS = {
  EMPTY_AMOUNT: 2,
  FILLED_AMOUNT: 6,
  NO_DIFF: 0,
};
export const LOGBOOK_DAY_AMOUNTS = {
  NO_DIFF: 0,
};

export const MOMENT_MONTH: moment.unitOfTime.DurationConstructor = 'month';
export const MOMENT_MONTHS: moment.unitOfTime.DurationConstructor = 'months';
export const MOMENT_DAYS: moment.unitOfTime.DurationConstructor = 'days';

export const LAW_TYPES = {
  WLZ: 'WLZ',
  JEUGDWET: 'Jeugdwet',
  JW: 'JW',
};

export const UNIT_TYPES = {
  CM: 'cm',
  KG: 'kg',
};

export const ACTION_TYPES = {
  CREATE: 'create',
  UPDATE: 'update',
};

export const EMAIL = {
  EMPLOYEE: 'support@prodeba.nl',
  GENERAL: 'c4p@prodeba.nl',
};

export const HOUR_VIEW_MODES = {
  EMPLOYEE_LOCATION: 'employee-location',
  CLIENT_LOCATION: 'client-location',
  CAREFILE: 'carefile',
  GROUP_SESSION_CLIENT: 'group-session-client',
  GROUP_SESSION_EMPLOYEE: 'group-session-employee',
  ZPM: 'zpm',
  PROJECT: 'project',
  LOCATION: 'location',
};
export const VIEW_MODE = {
  EDIT: 'edit',
  VIEW: 'view',
};
export const MEDICATION_STATUS = {
  PENDING: 'pending',
  ERROR: 'error',
  COMPLETED: 'completed',
};
export const INTERVAL_DURATION = 5000;
export const GROUP_CONSULT_CONSTANTS = {
  ASSESSMENT_ID_EXTERNAL_CODE: 'Assessment Id/External Code',
  NUMBER_OF_CHARGE: '# of charge',
  NAME: 'name',
  ASC: 'asc',
  DSC: 'dsc',
  CREATED_AT: 'createdAt',
  DATE: 'date',
  START_TIME: 'startTime',
  END_TIME: 'endTime',
  FULL_NAME: 'fullName',
  CLIENT_ID: 'clientId',
  ZPM: 'zpm',
  ACTIVITY: 'activity',
  NOTES: 'notes',
  CAREPROGRAM: 'careprogram',
  ASSESSMENT: 'assessment',
  INTERPRETER: 'interpreter',
  INTERNAL_PRODUCT: 'internalProduct',
  ID: 'id',
  TENANT_ID: 'tenantId',
  REMARKS: 'remarks',
  STATUS: 'status',
  EMPLOYEES: 'employees',
  CLIENTS: 'clients',
  OCCUPATION: 'occupation',
  OCCUPATION_CATEGORY: 'occupationCategory',
  FUNCTION: 'function',
  NUMBER: 'number',
  CLIENT_FULL_NAME: 'clientFullName',
  TYPE: 'type',
  DURATION: 'duration',
  NOTES_FROM_CONTACT_BOOK: 'notesFromContactBook',
  OPERATIONS: 'operations',
  EMPLOYEE: 'employee',
  CLIENT: 'client',
  LARGE_LIMIT_NUMBER: 999,
  SMALL_LIMIT_NUMBER: 20,
  SMALLEST_LIMIT_NUMBER: 10,
  VIEW: 'view',
  CONFIRM: 'confirm',
  EDIT: 'edit',
  GROUPCONSULTS: 'groupconsults',
  TYPEOFCARE_GROUP_SESSION: '2',
  VISIBILITY: 'visibility',
  CREATE: 'create',
  DONE: 'done',
  DRAFT: 'draft',
  BLOCKER: 'blocker',
  PERFORMANCE_CODE: 'performanceCode',
};

export const MAX_ALLOWED_FILE_SIZE: number = 20971520;

export const TRANSACTION_CONSTANTS = {
  DATE: 'date',
  ASC: 'asc',
  SMALLEST_LIMIT_NUMBER: 10,
};

export const SUPERSET_CONSTANTS = {
  DASHBOARD: 'dashboard',
  TARIFF_AND_TRENDS: 'TariffsAndTrends',
  EMPLOYEE_DASHBOARD: 'EmployeeDashboard',
  TEAM_LEAD_DASHBOARD: 'TeamLeadDashboard',
  MANAGEMENT_DASHBOARD: 'ManagementDashboard',
  CLIENT_OVERVIEW: 'ClientOverview',
  MY_CLIENTS: 'MyClients',
  MY_TEAM: 'MyTeam',
  MY_TEAM_PRODUCTIVITY: 'MyProductivity',
  MY_TEAM_REVENUE: 'MyTeamRevenue',
  MY_PRODUCTIVITY: 'MyProductivity',
  EXTERNAL_PRODUCTS: 'ExternalProducts',
  PERFORMANCE_TARIFF: 'PerformanceTariff',
  DASHBOARD_UI_CONFIG: {
    hideTitle: true,
    hideChartControls: true,
    hideTab: true,
    filters: {
      expanded: true,
      visible: true,
    },
  },
};

export const PORTAL_NAMES = {
  EMPLOYEE: 'Employee',
  CLIENT: 'Client',
  TENANT: 'Tenant',
  ICOMM: 'Icomm',
};
export const DIGITAL_SIGNATURE = {
  PDF: '.pdf',
  SIGNED: 'signed',
  ORIGINAL: 'original',
  PENDING: 'pending',
};

export const SING_MODE = {
  SIGNED: 'signed',
  PENDING: 'pending',
};

export const DOCUMENT_MODE = {
  VIEW: 'view',
  DOWNLOAD: 'download',
};
export const AUDIT_LOGS_PAGE = {
  APPLICATION: 'applications',
  CAREFILES: 'carefiles',
};

export const TRANSACTION_STATES = {
  TRANSACTION_CANCEL: 'TransactionCancel',
  TRANSACTION_ERROR: 'TransactionError',
  TRANSACTION_COMPLETED: 'TransactionCompleted',
};

export const TRANSACTION_STATUSES = {
  CANCELLED: 'cancelled',
  STARTED: 'started',
  COMPLETED: 'completed',
  ERROR: 'error',
};

export const INVOICE_CONSTANTS = {
  INVOICE_NUMBER: 'INVOICE_NUMBER',
  INVOICE_PERIOD: 'INVOICE_PERIOD',
};

export const LONG_TOASTER_MS = 5000;

export const DEPRECATED_FREQUENCY_4_WEEKS = '3';

export const CONTACT_LOGBOOK_CONST = {
  STATUS: 'open',
  CLOSE_BUTTON_NAME: 'close',
  DISCARD: 'general.actions.Discard',
  CANCEL_LOGBOOK_TITLE: 'general.messages.CancelLogbook',
  DISCARD_LOGBOOK_MESSAGE: 'careprogram.messages.AreYouSureYouWantToDiscard',
};

export const HOUR_LINK_FORMAT = {
  HOUR_LINK_REPORT_ICON: 'description',
  HOUR_LINK_REPORT_ICON_TEXT: 'general.labels.LinkedToReport',
  HOUR_LINK_CONTACT_LOGBOOK_ICON: 'menu_book',
  HOUR_LINK_CONTACT_LOGBOOK_ICON_TEXT: 'general.labels.LinkedToContactLogbook',
};
export const BUTTON_TYPE = {
  OK: 'Ok',
  ADD: 'Add',
};

export const EXCLUDED_CATEGORY_NAME = 'Data export';
