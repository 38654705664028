<ng-template #actionTemplate>

  @if (!overflow) {
  <div matTooltip="{{name | translate}}" [class.disabled]="disabled" (click)="handleClick()">
    <mat-icon class="c4p-action-icons"> {{ icon }} </mat-icon>
  </div>
  } @else {
  <div mat-menu-item class="c4p-menu-item" [class.disabled]="disabled" (click)="handleClick()">
    <mat-icon class="c4p-action-menu-icon"> {{ icon }} </mat-icon>
    <span class="c4p-menu-title">{{name | translate}}</span>
  </div>
  }
</ng-template>
